import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import creadvestLogo from "../../assets/logo/creadvestLogo.png";
import ClientIcon from "../../assets/icons/ClientIcon.svg";
import HamburgerMenu from "react-hamburger-menu";
import DropdownIcon from "../../assets/icons/DropdownIcon.svg";
import styles from "./header.module.scss";

function Header() {
  const dropdownRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const strategyPaths = ["/Marketing", "/Branding", "/Sales", "/CRM"];

  // useEffect(() => {
  //   let lastScrollTop = 0;
  //   const handleScroll = () => {
  //     let st = window.pageYOffset || document.documentElement.scrollTop;
  //     if (st < lastScrollTop || st === 0) {
  //       setIsVisible(true);
  //     } else {
  //       setIsVisible(false);
  //     }
  //     lastScrollTop = st;
  //   };

  //   const handleMouseMove = (e) => {
  //     if (e.clientY <= 50) {
  //       // Check if the mouse is within 50 pixels from the top
  //       setIsVisible(true);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   window.addEventListener("mousemove", handleMouseMove);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //     window.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  // useEffect(() => {
  //   let lastScrollTop = 0;
  //   const handleScroll = () => {
  //     let st = window.pageYOffset || document.documentElement.scrollTop;
  //     if (st < lastScrollTop || st === 0) {
  //       setIsVisible(true); // Show header when scrolling up or at the top of the page
  //     } else {
  //       setIsVisible(false); // Hide header when scrolling down
  //     }
  //     lastScrollTop = st;
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const getLinkStyle = (paths) => {
    return paths.includes(location.pathname)
      ? { color: "#FF4A1C" }
      : { color: "black" };
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
    setIsDropdownOpen(false);
    handleScroll(false);
  };

  const handleDropdownBlur = (event) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.relatedTarget)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleScroll = (lock) => {
    if (lock) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  };
  const links = [
    { name: "Home", link: "/" },
    { name: "Services", link: "/services" },
    { name: "Credvest Group", link: "/credvestGroup" },
    { name: "Ventures", link: "/ourVentures" },
    { name: "Contact Us", link: "/ContactUs" },
  ];

  return (
    <div
      className={`${styles.parentContainer} ${isVisible ? "" : styles.hiddenHeader
        } py-4`}
    >
      <div
        className={`${styles.headerContainer} ${location.pathname === "/"
          ? styles.absolutePosition
          : styles.relativePosition
          }`}
      >
        <Link
          to="/"
          className="flex-shrink-0 z-50"
          onClick={closeMenu}
          className={styles.credvestLogoCon + ""}
        >
          <img
            src={creadvestLogo}
            alt="Logo"
            className={
              styles.credvestLogo + " w-24 sm:w-30 md:w-36 lg:w-40 xl:w-44 "
            }
          />
        </Link>
        <button
          onClick={() => {
            setIsMenuOpen(!isMenuOpen);
            handleScroll(!isMenuOpen);
          }}
          className={`${styles.hiddenOnLarge} p-2 relative`}
          style={{ zIndex: 99 }}
        >
          <HamburgerMenu
            isOpen={isMenuOpen}
            menuClicked={() => {
              setIsMenuOpen(!isMenuOpen);
              handleScroll(!isMenuOpen);
            }}
            width={18}
            height={13}
            strokeWidth={2}
            rotate={0}
            color="black"
            borderRadius={0}
            animationDuration={0.5}
          />
        </button>
        <div
          key={isMenuOpen}
          className={`${isMenuOpen ? "flex" : "hidden"
            } items-center transition-all duration-500 ease-in-out fixed inset-0 z-50 flex flex-col justify-around space-y-4 ${styles.popupBgColor
            }`}
        >
          <div
            className={`items-center font-bold font-oxygen flex flex-col justify-center space-y-4`}
          >
            <Link
              to="/"
              onClick={closeMenu}
              style={getLinkStyle(["/"])}
              className="hover:text-red-600 text-sm lg:text-base"
            >
              Home
            </Link>
            <Link
              to="/services"
              onClick={closeMenu}
              style={getLinkStyle(["/services"])}
              className=" text-sm lg:text-base"
            >
              <div className="hover:text-red-600">Services</div>
            </Link>
            <Link
              to="/credvestGroup"
              onClick={closeMenu}
              style={getLinkStyle(["/credvestGroup"])}
              className=" text-sm lg:text-base"
            >
              <div className="hover:text-red-600">Credvest Group</div>
            </Link>
            <Link
              to="/ourVentures"
              onClick={closeMenu}
              style={getLinkStyle(["/ourVentures"])}
              className=" text-sm lg:text-base"
            >
              <div className="hover:text-red-600">Ventures</div>
            </Link>
            <Link
              to="/ContactUs"
              onClick={closeMenu}
              style={getLinkStyle(["/ContactUs"])}
              className=" text-sm lg:text-base"
            >
              <div className="hover:text-red-600">Contact us</div>
            </Link>
          </div>
          {/* <div className="button">
            <img
              src={ClientIcon}
              alt="Client Icon"
              className="w-4 sm:w-6 md:w-6 lg:w-5"
            />
            <span className="text-white text-sm lg:text-base">
              Client Login
            </span>
          </div> */}
        </div>
        <div
          className={`${styles.flexOnLarge} space-x-2 lg:space-x-6 xl:space-x-6 text-xs lg:text-sm xl:text-base items-center font-bold`}
        >
          {links.map((item) => (
            <Link
              key={item.name}
              to={item.link}
              style={getLinkStyle([item.link])}
            >
              <div className="hover:text-red-600">{item.name}</div>
            </Link>
          ))}
        </div>
        <div className="hehe max-lg:hidden"></div>
        <div className="hehe max-lg:hidden"></div>
      </div>
    </div>
  );
}

export default Header;
