import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { Helmet } from "react-helmet";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";

import Home from "./pages/Home/Home";
import OurVentures from "./pages/ourVentures/ourVentures";
import Branding from "./pages/branding/Branding";
import CaseStudies from "./pages/caseStudies/CaseStudies";
import ContactUs from "./pages/contactUs/contactUs";
import CRM from "./pages/crm/crm";
import Culture from "./pages/culture/Culture";
import DirectorCuts from "./pages/directorsCut/DirectorCuts";
import Sales from "./pages/sales/Sales";
import Marketing from "./pages/marketing/Marketing";
import KnowGood from "./pages/knowGood/knowGood";
import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Thankyou from "./components/Thankyou/Thankyou";

import { createGlobalStyle } from "styled-components";
import Services from "./pages/services/services";
import CredvestGroup from "./pages/credvestGroup/CredvestGroup";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

  body {
    font-family: 'Source Sans Pro', sans-serif;
  }
`;

function AppRoutes() {
  useEffect(() => {
    (async function () {
      const LS = (await import("locomotive-scroll")).default;
      const locomotiveScroll = new LS();
    })();
  }, []);
  const location = useLocation();
  const isThankyouPage = location.pathname === "/thankyou";

  return (
    <>
      <div className={"zIndex relative"}>
        {!isThankyouPage && <Header />} {/* Conditionally render Header */}
      </div>
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>
                    Development and Branding Pioneers in Real Estate | Credvest
                  </title>
                  <meta
                    name="description"
                    content="Explore cutting-edge advancements in the real estate industry. Know Credvest. Know Good."
                  />
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/knowGood"
            element={
              <>
                <Helmet>
                  <title>Find out About Us | Credvest </title>
                  <meta
                    name="description"
                    content="Quiet confidence, unmatched work culture, and exceptional people – Just, Know good! "
                  />
                </Helmet>
                <KnowGood />
              </>
            }
          />
          <Route
            path="/about-us"
            element={
              <>
                <Helmet>
                  <title>Find out About Us | Credvest </title>
                  <meta
                    name="description"
                    content="Quiet confidence, unmatched work culture, and exceptional people – Just, Know good! "
                  />
                </Helmet>
                <KnowGood />
              </>
            }
          />
          <Route
            path="/branding"
            element={
              <>
                <Helmet>
                  <title>Leverage Branding for your Business | Credvest </title>
                  <meta
                    name="description"
                    content="Elevate your real estate brand with Credvest's expertise. Stand out, build trust, and leave a lasting impression in a competitive market."
                  />
                </Helmet>
                <Branding />
              </>
            }
          />
          <Route
            path="/casestudies"
            element={
              <>
                <Helmet>
                  <title>Dive into our Case Studies | Credvest</title>
                  <meta
                    name="description"
                    content="Discover Credvest success stories – To know good is to know Credvest"
                  />
                </Helmet>
                <CaseStudies />
              </>
            }
          />
          <Route
            path="/contactus"
            element={
              <>
                <Helmet>
                  <title>Get in touch | Credvest</title>
                  <meta
                    name="description"
                    content="Connect with us today to take the first step toward your success journey."
                  />
                </Helmet>
                <ContactUs />
              </>
            }
          />
          <Route
            path="/services"
            element={
              <>
                <Helmet>
                  <title>Get in touch | Credvest</title>
                  <meta
                    name="description"
                    content="Connect with us today to take the first step toward your success journey."
                  />
                </Helmet>
                <Services />
              </>
            }
          />
          <Route
            path="/crm"
            element={
              <>
                <Helmet>
                  <title>Our CRM Solutions for you | Credvest</title>
                  <meta
                    name="description"
                    content="Transform your real estate journey with our comprehensive CRM solutions."
                  />
                </Helmet>
                <CRM />
              </>
            }
          />
          <Route
            path="/culture"
            element={
              <>
                <Helmet>
                  <title>
                    Explore Job Opportunities & Embrace our Culture | Credvest
                  </title>
                  <meta
                    name="description"
                    content="Credvest: Where Passion, Commitment, and Employpreneurship thrive."
                  />
                </Helmet>
                <Culture />
              </>
            }
          />
          <Route
            path="/directorscut"
            element={
              <>
                <Helmet>
                  <title>Thought Leadership Articles | Credvest</title>
                  <meta
                    name="description"
                    content="Hear it from our experts on various topics."
                  />
                </Helmet>
                <DirectorCuts />
              </>
            }
          />
          <Route
            path="/sales"
            element={
              <>
                <Helmet>
                  <title>Boost your Sales | Credvest t</title>
                  <meta
                    name="description"
                    content="Decades of real estate experience, personalized service, and a vast network for your competitive edge."
                  />
                </Helmet>
                <Sales />
              </>
            }
          />
          <Route
            path="/marketing"
            element={
              <>
                <Helmet>
                  <title>
                    Leverage Marketing for your Business | Credvest{" "}
                  </title>
                  <meta
                    name="description"
                    content="Navigate changing real estate marketing with Credvest's pillars: Technology, Visibility, Storytelling."
                  />
                </Helmet>
                <Marketing />
              </>
            }
          />
          <Route
            path="/privacyPolicy"
            element={
              <>
                <Helmet>
                  <title>Privacy Policy | Credvest</title>
                  <meta name="description" content="Privacy Policy" />
                </Helmet>
                <PrivacyPolicy />
              </>
            }
          />
          <Route
            path="/thankyou"
            element={
              <>
                <Helmet>
                  <title>Thankyou | Credvest</title>
                  <meta name="description" content="Thankyou " />
                </Helmet>
                <Thankyou />
              </>
            }
          />{" "}
          <Route
            path="/ourVentures"
            element={
              <>
                <Helmet>
                  <title>Synergy in Action | CredVest</title>
                  <meta
                    name="description"
                    content="Explore cutting-edge advancements in the real estate industry. Know Credvest. Know Good."
                  />
                </Helmet>
                <OurVentures />
              </>
            }
          />
          <Route
            path="/credvestGroup"
            element={
              <>
                <Helmet>
                  <title>The Credvest Group | CredVest</title>
                  <meta
                    name="description"
                    content="Explore cutting-edge advancements in the real estate industry. Know Credvest. Know Good."
                  />
                </Helmet>
                <CredvestGroup />
              </>
            }
          />
        </Routes>
      </div>
      {!isThankyouPage && <Footer />}
    </>
  );
}

function App() {
  return (
    <Router>
      <GlobalStyle />
      <AppRoutes />
    </Router>
  );
}

export default App;
